
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as documentsPnQskFFlm7XZfu203sYr3VPlcLjrZGC23j_8owWpy0gMeta } from "/usr/src/app/pages/documents.vue?macro=true";
import { default as indexdP7zTFRl7aZq2Pc08cwkTh9_Xhz5atMbl890jb4bLIEMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as investmentsEvGs1ewaKNg20rMagkngDOwZF8VZSnHXDDpqn8FbEYQMeta } from "/usr/src/app/pages/investments.vue?macro=true";
import { default as loginWs2S4x_45jM_t2WOuoeODzX_45F9bUBoxDNgn4jXvry9FDkMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as logout7NjG__45Utg7A9l9_yI58wB4TBq91M3faPHbJEh6QnX4gMeta } from "/usr/src/app/pages/logout.vue?macro=true";
import { default as _91id_935okzh1lbLZmJNy9Fqx27XmbNE8f_458SDaKoeGlzmBmdYMeta } from "/usr/src/app/pages/news/[id].vue?macro=true";
import { default as indexd22lU6Pp9lyYcKW9nhUxKrvl6VaSljmv_45EgIKOs7uLwMeta } from "/usr/src/app/pages/news/index.vue?macro=true";
import { default as _91id_93dRtPFC5CjMWYf7lpjUPH3q1cXfl4wED7Lk6vq1fFsLYMeta } from "/usr/src/app/pages/products/[id].vue?macro=true";
import { default as indexMoRJ68d_45GisCGWiVNfTDmv1uh1JPvTCfF28ZHoFDipAMeta } from "/usr/src/app/pages/products/index.vue?macro=true";
import { default as settingskX0Jub0vvRcsWAHjHJxle6Ey_17ybU0HEipUBC_45ZbxAMeta } from "/usr/src/app/pages/settings.vue?macro=true";
import { default as transactionsYZa02IppYaGlhYV5mXrWBmpjj15zsPXrh43thWDX0KgMeta } from "/usr/src/app/pages/transactions.vue?macro=true";
import { default as visitor6YVSyIwNMXcIMdBD1Al_LMnQoZBDKwAVVZFSHByg4sIMeta } from "/usr/src/app/pages/visitor.vue?macro=true";
import { default as documents7_DYPq8779fTX9Gn6aG9g5SzaBW_45x1PyD1UW_45Fcr_aYMeta } from "~/pages/documents.vue?macro=true";
export default [
  {
    name: "documents",
    path: "/documents",
    component: () => import("/usr/src/app/pages/documents.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "investments",
    path: "/investments",
    component: () => import("/usr/src/app/pages/investments.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginWs2S4x_45jM_t2WOuoeODzX_45F9bUBoxDNgn4jXvry9FDkMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout7NjG__45Utg7A9l9_yI58wB4TBq91M3faPHbJEh6QnX4gMeta || {},
    component: () => import("/usr/src/app/pages/logout.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/usr/src/app/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/usr/src/app/pages/news/index.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    component: () => import("/usr/src/app/pages/products/[id].vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/usr/src/app/pages/products/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/app/pages/settings.vue")
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/usr/src/app/pages/transactions.vue")
  },
  {
    name: "visitor",
    path: "/visitor",
    meta: visitor6YVSyIwNMXcIMdBD1Al_LMnQoZBDKwAVVZFSHByg4sIMeta || {},
    component: () => import("/usr/src/app/pages/visitor.vue")
  },
  {
    name: "documents-personal",
    path: "/documents/personal",
    component: () => import("~/pages/documents.vue")
  },
  {
    name: "documents-funds",
    path: "/documents/funds",
    component: () => import("~/pages/documents.vue")
  },
  {
    name: "documents-notices",
    path: "/documents/notices",
    component: () => import("~/pages/documents.vue")
  },
  {
    name: "documents-subscriptions",
    path: "/documents/subscriptions",
    component: () => import("~/pages/documents.vue")
  }
]