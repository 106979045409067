import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8geMXmCD2t7LWdz1cPAusc6bDgjVZXVqHcSQAUaPye8 from "/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import motion_F8atB0kBNE8FIgT_ajBXU_q0VzJ7X_J9WFqahA8C37U from "/usr/src/app/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import echarts_client_B3Hm_2aQLp1fKdfjsaJfTNN4xdsenSpoJBbiyBow468 from "/usr/src/app/plugins/echarts.client.ts";
import floating_vue_client_drBcqNbyjVwuto_P9boXGwGv7_vvrV6pIBvKhTvdMfY from "/usr/src/app/plugins/floating-vue.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/usr/src/app/plugins/sentry.client.ts";
import vue_i18n_jI8AvF4RSekjnVPGsQoTzWmktetgljt_BYovGMVVAmI from "/usr/src/app/plugins/vue-i18n.ts";
import vue_pdf_embed_client_uijqxOG_ELfbdZ4iLQmkD2K1yH7othTOIqYIvZC0bbw from "/usr/src/app/plugins/vue-pdf-embed.client.ts";
import vue_toastification_IkltnQZhoUdE4eb98IR0i_pLqVk9_IP95352xc1s_bE from "/usr/src/app/plugins/vue-toastification.ts";
import xstate_inspect_client_OnyREMEW7WdSyAyK_1a39Oku3NMl31GUaf_wjpn4lck from "/usr/src/app/plugins/xstate-inspect.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_8geMXmCD2t7LWdz1cPAusc6bDgjVZXVqHcSQAUaPye8,
  motion_F8atB0kBNE8FIgT_ajBXU_q0VzJ7X_J9WFqahA8C37U,
  echarts_client_B3Hm_2aQLp1fKdfjsaJfTNN4xdsenSpoJBbiyBow468,
  floating_vue_client_drBcqNbyjVwuto_P9boXGwGv7_vvrV6pIBvKhTvdMfY,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  vue_i18n_jI8AvF4RSekjnVPGsQoTzWmktetgljt_BYovGMVVAmI,
  vue_pdf_embed_client_uijqxOG_ELfbdZ4iLQmkD2K1yH7othTOIqYIvZC0bbw,
  vue_toastification_IkltnQZhoUdE4eb98IR0i_pLqVk9_IP95352xc1s_bE,
  xstate_inspect_client_OnyREMEW7WdSyAyK_1a39Oku3NMl31GUaf_wjpn4lck
]